<template>
  <div style="padding: 8px 8px">
    <a-form>
      <div class="title">{{ stringList.comments }}</div>
      <div class="content">
        <a-textarea
          class="text-area"
          :placeholder="stringList['enter_comments200']"
          :rows="4"
          :value="value"
          @input="checkInput"
        />
        <div class="error-message">{{errorMessage}}</div>
      </div>
      <div class="submit">
        <van-button class="button" native-type="submit" @click="close">{{
          stringList.OK
        }}</van-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Comments",
  data() {
    return {
      errorMessage:""
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      stringList: "stringList",
      languageList: "languageList",
      language: "language",
    }),
  },
  watch:{
    value(){
      this.errorMessage = ""
    }
  },
  mounted() {
    this.errorMessage = ""
  },
  methods: {
    checkInput($event) {
      if ($event.target.value.length > 200) {
        $event.target.value = $event.target.value.slice(0, 200);
        this.errorMessage = this.stringList["200code"]
        // this.$message.info({
        //   content: this.stringList["200code"],
        //   key: "200code",
        //   icon: () => {
        //     return "";
        //   },
        //   // duration: 2,
        // });
      }else{
        this.errorMessage = ""
      }
      this.$emit("update:value", $event.target.value);
    },
    close() {
      this.$emit("close");
      // console.log("log out!");
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  height: 1.625rem;
  opacity: 1;
  font-family: Arial;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0em;
  color: #5a578c;
}
.content {
  margin-top: 1.5rem;
  .text-area {
    height: 11.5rem;
    border-radius: 12px;
    opacity: 1;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #afafaf;
    padding: 16px;
    resize: none;
  }
  .error-message {
    opacity: 1;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #df714f;
    line-height: 18px;
  }
}
.submit {
  margin: 32px 0 16px;
  text-align: center;
  .button {
    width: 160px;
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
  }
}
// 未生效
/deep/.ant-message-notice-content {
  width: 320px;
  height: 108px;
  padding: 32px;
  border-radius: 32px;
  background: #f8f7f8;
  .ant-message-custom-content.ant-message-info {
    height: 44px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    color: #101010;
    .anticon.anticon-info-circle {
      display: none;
    }
  }
}

// body :global(.ant-message-notice-content ){
//   width: 320px;
//   height: 108px;
//   padding: 32px !important;
//   border-radius: 32px !important;
//   background: #f8f7f8 !important;
// }
// body :global(.ant-message-custom-content.ant-message-info ){

//     height: 44px;
//     opacity: 1;
//     font-family: Arial;
//     font-size: 16px;
//     font-weight: normal;
//     line-height: 22px;
//     display: flex !important;
//     align-items: center;
//     letter-spacing: 0em;
//     color: #101010;
//     .anticon.anticon-info-circle {
//       display: none;
//     }
// }
</style>
