<template>
  <div style="padding: 8px 12px">
    <a-form>
      <div class="title">{{ stringList.cancel_checklist }}</div>
      <div class="content">{{ stringList.cancel_submitted }}</div>
      <div class="submit">
        <van-button class="button-yes" native-type="submit" @click="complete">{{
          stringList.Yes
        }}</van-button>
        <van-button class="button-no" native-type="submit" @click="cancel">{{
          stringList.No
        }}</van-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Cancel",
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      stringList: "stringList",
      languageList: "languageList",
      language: "language",
    }),
  },
  mounted() {},
  methods: {
    complete() {
      this.$emit("complete");
    },
    cancel() {
      this.$emit("cancel");
      // console.log("log out!");
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  // height: 1.625rem;
  opacity: 1;
  font-family: Arial;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0em;
  color: #5a578c;
}
.content {
  margin-top: 1.5rem;
  height: 44px;
  opacity: 1;
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;

  color: #101010;
}
.submit {
  margin: 32px 0 16px;
  text-align: center;
  .button-yes {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
    width: 106px;
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ff6600;
    margin-right: 20px;
  }
  .button-no {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #3d3d3d;
    width: 106px;
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ffffff;

    box-sizing: border-box;
    border: 2px solid #cbcfe0;
  }
}
// 未生效
/deep/.ant-message-notice-content {
  width: 320px;
  height: 108px;
  padding: 32px;
  border-radius: 32px;
  background: #f8f7f8;
  .ant-message-custom-content.ant-message-info {
    height: 44px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    color: #101010;
    .anticon.anticon-info-circle {
      display: none;
    }
  }
}
</style>
