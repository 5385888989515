<template>
  <div id="app" :class="language">
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters({
      language: "language",
    }),
  },
  watch: {
    language: {
      handler() {
        // console.log(document.getElementsByTagName("html"));
        document.getElementsByTagName("html")[0].classList = this.language;
        // if(this.language == "en"){
        //   document.getElementsByTagName("html")[0].append(`<style>*{ word-break: keep-all !important;}</style>`)
        // }else{

        // }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less">

#app {
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.room-select {
  .ant-select-dropdown-menu {
    max-height: 150px !important;
  }
}
*{
  word-break: break-all !important;
}
.en{
  *{
    word-break: keep-all !important;
  }
}
@media screen and (min-width: 700px) {
  #app {
    width: 390px !important;
  }
  .foodModel {
    width: 390px !important;
    left: auto !important;
  }
  .van-overlay {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--bottom {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--top {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-sticky--fixed {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-toast--html {
    width: 300px !important;
  }
}
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
