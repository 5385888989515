import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "./modules/user";
import config from "./modules/config";
import qrcode from "./modules/qrcode";
import language from "./modules/language";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    config,
    qrcode,
    language
  },
  getters,
});
