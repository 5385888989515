const getters = {
  primaryColor: (state) => state.config.primaryColor,
  amcniticsRequest: (state) => state.qrcode.amcniticsRequest,
  informationRequest: (state) => state.qrcode.informationRequest,
  showPhone: (state) => state.qrcode.showPhone,
  roomList: (state) => state.qrcode.roomList,
  language: (state) => state.language.language,
  languageList: (state) => state.language.languageList,
  stringList: (state) => state.language.stringList,
};
export default getters;
